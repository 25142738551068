import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import SEO from "../../components/SEO";
import IntroHeader from "../../components/IntroHeader";
import { Container, Section } from "../../components/LayoutUtils";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";
import GalleryItemList from "../../components/GalleryItemList";
import PriceList from "../../components/PriceList";
import Markdown from "../../components/Markdown";
import Quotebox from "../../components/QuoteBox";

const garmentTypeMap = {
  suits: ["formal-wear", "suits"],
  coats: ["coats"],
  "casual-wear": ["jackets", "trousers"],
  jackets: ["jackets", "trousers", "casual-wear"],
  "formal-wear": ["formal-wear", "suits"]
};

const garmentSearchMapper = term => {
  const map = {
    "casual-wear": "casual",
    "formal-wear": "formal",
    coats: "'coat !waistcoat"
  };
  const reterm = map[term];
  if (reterm) return reterm;
  return term;
};

const GarmentPage = ({ data, pageContext }) => {
  const page = data.contentfulPage;
  const { product } = pageContext;
  return (
    <Layout>
      <SEO
        image={getPageImage(page)}
        title={product.name}
        description={`${product.name}: ${product.subtitle}`}
      />
      <IntroHeader
        {...page}
        introHeaderTitle={product.name}
        introHeaderSubtitle={product.subtitle}
      />
      <Container>
        <Section>
          <div className="row">
            <div className="col-sm-5 col-md-6">
              <Markdown source={product.shortDescription.shortDescription} />
            </div>
            <div className="col-sm-7 offset-md-1 col-md-5">
              <PriceList
                naviagate={false}
                garmentTypes={garmentTypeMap[product.slug]}
              />
              <Section>
                <Quotebox>
                  The price of our handmade city tailored{" "}
                  {product.name.toLowerCase()} depends on a few things, but
                  mainly the cloth.{" "}
                  <Link to="pricing">Read more about our prices</Link>.
                </Quotebox>
              </Section>
            </div>
          </div>
        </Section>
        <Section>
          <GalleryItemList
            navigate={false}
            filter={garmentSearchMapper(product.slug)}
          />
        </Section>
      </Container>
    </Layout>
  );
};

GarmentPage.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "gallery" }) {
      ...page
    }
  }
`;

export default GarmentPage;
